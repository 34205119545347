export const FETCH_LOGIN_REQUEST = 'FETCH_LOGIN_REQUEST'
export const FETCH_LOGIN_SUCCESS = 'FETCH_LOGIN_SUCCESS'
export const FETCH_LOGIN_ERROR = 'FETCH_LOGIN_ERROR'

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR'

export const SET_CURRENT_PLAYER_REQUEST = 'SET_CURRENT_PLAYER_REQUEST'
export const SET_CURRENT_PLAYER_SUCCESS = 'SET_CURRENT_PLAYER_SUCCESS'
export const SET_CURRENT_PLAYER_ERROR = 'SET_CURRENT_PLAYER_ERROR'

export const EDIT_PLAYER_REQUEST = 'EDIT_PLAYER_REQUEST'
export const EDIT_PLAYER_SUCCESS = 'EDIT_PLAYER_SUCCESS'
export const EDIT_PLAYER_ERROR = 'EDIT_PLAYER_ERROR'

export const UPDATE_BALANCE_REQUEST = 'UPDATE_BALANCE_REQUEST'
export const UPDATE_BALANCE_SUCCESS = 'UPDATE_BALANCE_SUCCESS'
export const UPDATE_BALANCE_ERROR = 'UPDATE_BALANCE_ERROR'

export const FETCH_BALANCE_REQUEST = 'FETCH_BALANCE_REQUEST'
export const FETCH_BALANCE_SUCCESS = 'FETCH_BALANCE_SUCCESS'
export const FETCH_BALANCE_ERROR = 'FETCH_BALANCE_ERROR'

export const FETCH_CURRENCY_REQUEST = 'FETCH_CURRENCY_REQUEST'
export const FETCH_CURRENCY_SUCCESS = 'FETCH_CURRENCY_SUCCESS'
export const FETCH_CURRENCY_ERROR = 'FETCH_CURRENCY_ERROR'

export const FETCH_PROVIDER_REQUEST = 'FETCH_PROVIDER_REQUEST'
export const FETCH_PROVIDER_SUCCESS = 'FETCH_PROVIDER_SUCCESS'
export const FETCH_PROVIDER_ERROR = 'FETCH_PROVIDER_ERROR'

export const FETCH_USERS_ONLINE_REQUEST = 'FETCH_USERS_ONLINE_REQUEST'
export const FETCH_USERS_ONLINE_SUCCESS = 'FETCH_USERS_ONLINE_SUCCESS'
export const FETCH_USERS_ONLINE_ERROR = 'FETCH_USERS_ONLINE_ERROR'

export const CREATE_PLAYER_MOCK_REQUEST = 'CREATE_PLAYER_MOCK_REQUEST'
export const CREATE_PLAYER_MOCK_SUCCESS = 'CREATE_PLAYER_MOCK_SUCCESS'
export const CREATE_PLAYER_MOCK_ERROR = 'CREATE_PLAYER_MOCK_ERROR'

export const CREATE_TRANSACTION_MOCK_REQUEST = 'CREATE_TRANSACTION_MOCK_REQUEST'
export const CREATE_TRANSACTION_MOCK_SUCCESS = 'CREATE_TRANSACTION_MOCK_SUCCESS'
export const CREATE_TRANSACTION_MOCK_ERROR = 'CREATE_TRANSACTION_MOCK_ERROR'

export const FETCH_GAMEPLAYS_REQUEST = 'FETCH_GAMEPLAYS_REQUEST'
export const FETCH_GAMEPLAYS_SUCCESS = 'FETCH_GAMEPLAYS_SUCCESS'
export const FETCH_GAMEPLAYS_ERROR = 'FETCH_GAMEPLAYS_ERROR'
