// Action Types.
import {
  SET_CURRENT_PLAYER_SUCCESS,
  SET_CURRENT_PLAYER_ERROR,
  SET_CURRENT_PLAYER_REQUEST,

  EDIT_PLAYER_REQUEST,
  EDIT_PLAYER_SUCCESS,
  EDIT_PLAYER_ERROR,

  UPDATE_BALANCE_REQUEST,
  UPDATE_BALANCE_SUCCESS,
  UPDATE_BALANCE_ERROR,

  FETCH_BALANCE_REQUEST,
  FETCH_BALANCE_SUCCESS,
  FETCH_BALANCE_ERROR
} from '../actions/actionTypes'

// Initial State.
const initialState = {
  player: null,
  currentBalance: null,
  currency: null,
  loading: false
}

const usersListReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_PLAYER_SUCCESS:

      return { ...state, player: action.payload, loading: false }

    case SET_CURRENT_PLAYER_REQUEST:

      return { ...state, loading: true }

    case SET_CURRENT_PLAYER_ERROR:

      return { ...state, loading: false }

    case EDIT_PLAYER_SUCCESS:

      return { ...state, player: action.payload, loading: false }

    case EDIT_PLAYER_REQUEST:

      return { ...state, loading: true }

    case EDIT_PLAYER_ERROR:

      return { ...state, loading: false }

    case UPDATE_BALANCE_SUCCESS:

      return {
        ...state,
        currentBalance: action.payload[0].player_id === state.player.id ? action.payload[0].amount : state.currentBalance,
        currency: action.payload[0].player_id === state.player.id ? action.payload[0].currency_code : state.currency,
        loading: false
      }

    case UPDATE_BALANCE_REQUEST:

      return { ...state, loading: true }

    case UPDATE_BALANCE_ERROR:

      return { ...state, loading: false }

    case FETCH_BALANCE_SUCCESS:

      return {
        ...state,
        currentBalance: action.payload[0].amount,
        loading: false
      }

    case FETCH_BALANCE_REQUEST:

      return { ...state, loading: true }

    case FETCH_BALANCE_ERROR:

      return { ...state, loading: false }

    default:
      return state
  }
}

export default usersListReducer
