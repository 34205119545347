// Action Types.
import {
  FETCH_CURRENCY_ERROR,
  FETCH_CURRENCY_SUCCESS
} from '../actions/actionTypes'

// Initial State.
const initialState = {
  currencies: []
}

const currencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CURRENCY_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { payload } = action
      return { ...state, currencies: payload }
    case FETCH_CURRENCY_ERROR:
      // eslint-disable-next-line no-case-declarations
      return { ...state, currencies: [] }
    default:
      return state
  }
}

export default currencyReducer
