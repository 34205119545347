import { error, received, request } from '../shared/redux/baseActions'
import axios from 'axios'

// Action Types
import {
  SET_CURRENT_PLAYER_ERROR,
  SET_CURRENT_PLAYER_SUCCESS,
  SET_CURRENT_PLAYER_REQUEST,
  EDIT_PLAYER_REQUEST,
  EDIT_PLAYER_SUCCESS,
  EDIT_PLAYER_ERROR,
  UPDATE_BALANCE_REQUEST,
  UPDATE_BALANCE_SUCCESS,
  UPDATE_BALANCE_ERROR,
  FETCH_BALANCE_REQUEST,
  FETCH_BALANCE_SUCCESS,
  FETCH_BALANCE_ERROR
} from './actionTypes'

// Will send token once it's required in the API
export const setActualPlayer = (player) => async (dispatch) => {
  dispatch(request(SET_CURRENT_PLAYER_REQUEST))
  if (player !== null) {
    dispatch(received(SET_CURRENT_PLAYER_SUCCESS, player))
  } else {
    dispatch(error(SET_CURRENT_PLAYER_ERROR))
  }
}

// Will send token once it's required in the API
export const editPlayer = (id, firstName, lastName, date) => async (dispatch, getState) => {
  // Dispatching our request action.
  dispatch(request(EDIT_PLAYER_REQUEST))

  const { playerDetails, session } = getState()
  const { player } = playerDetails

  const URL = process.env.REACT_APP_SERVER_URL + `players/${id}`

  const form = {
    id: id,
    company_id: player.company_id,
    first_name: firstName,
    last_name: lastName,
    date_of_birth: date,
    contact_number: player.contact_number,
    country: player.country,
    address: player.address,
    city: player.city,
    post_code: player.post_code,
    email: player.email,
    currency_id: player.currency_id,
    offer_email: player.offer_email,
    created_at: player.created_at,
    updated_at: player.updated_at
  }

  try {
    const response = await axios.put(URL, form, { headers: { Authorization: `Bearer ${session.token}` } })
    dispatch(received(EDIT_PLAYER_SUCCESS, response.data.result))
  } catch (err) {
    dispatch(error(EDIT_PLAYER_ERROR))
  }
}

export const updateBalance = (playerBalance) => (dispatch) => {
  dispatch(request(UPDATE_BALANCE_REQUEST))
  if (playerBalance !== null) {
    dispatch(received(UPDATE_BALANCE_SUCCESS, playerBalance))
  } else {
    dispatch(error(UPDATE_BALANCE_ERROR))
  }
}

export const fetchBalance = (id) => async (dispatch, getState) => {
  dispatch(request(FETCH_BALANCE_REQUEST))
  const URL = process.env.REACT_APP_SERVER_URL + `balances/player/${id}`
  const { session } = getState()

  try {
    const response = await axios.get(URL, { headers: { Authorization: `Bearer ${session.token}` } })
    dispatch(received(FETCH_BALANCE_SUCCESS, response.data.result || []))
  } catch (err) {
    dispatch(error(FETCH_BALANCE_ERROR))
  }
}
