import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import merge from 'lodash/merge'

import axiosClient from './client'

export default function useAxiosClientAuth () {
  const { token } = useSelector((state) => state.session)

  useEffect(() => {
    const interceptorId = axiosClient.interceptors.request.use((config) => {
      if (!token) return config

      return merge({}, config, { headers: { Authorization: `Bearer ${token}` } })
    })

    return () => axiosClient.interceptors.request.eject(interceptorId)
  }, [token])
}
