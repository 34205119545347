import axiosClient from './client'

export { default as useAxiosClientAuth } from './clientAuthHook'

export { axiosClient }

export async function getPlayerBalances (playerId) {
  const {
    data: { result }
  } = await axiosClient(`/balances/player/${playerId}`)

  return result
}

export async function getPlayerGames (playerId) {
  const {
    data: { result }
  } = await axiosClient(`/games/played/${playerId}`)

  return result
}

export async function getAnalyticsReports (body) {
  const {
    data: { result }
  } = await axiosClient.post('/analytics/report', body)

  return result
}

export async function getUserTransactions (playerId) {
  const {
    data: { result }
  } = await axiosClient(`/transactions/player/${playerId}`)

  return result
}

export async function getTransactions () {
  const {
    data: { result }
  } = await axiosClient('/transactions')

  return result
}

export async function getReports (type) {
  const {
    data: { result }
  } = await axiosClient.post('/analytics/report', [
    { field_name: 'name', symbol: '=', value: type }
  ])

  return result
}

export async function getPlayersOnline () {
  const {
    data: { result }
  } = await axiosClient('/players/online')

  return result
}

export async function getGameplays () {
  const {
    data: { result }
  } = await axiosClient('/games/played')

  return result
}

export async function getPlayers () {
  const {
    data: { result }
  } = await axiosClient('/players')

  return result
}

export async function getPlayer (playerId) {
  const {
    data: { result }
  } = await axiosClient(`/players/${playerId}`)

  return result
}

export async function getPlayerChangelog (playerId) {
  const {
    data: { result }
  } = await axiosClient(`/players/changelog/${playerId}`)

  return result
}

export async function updatePlayer (playerId, data) {
  const {
    data: { result }
  } = await axiosClient.put(`/players/${playerId}`, data)

  return result
}

export async function postBonusTransaction (data) {
  const {
    data: { result }
  } = await axiosClient.post('/transactions/bonus', data)

  return result
}
