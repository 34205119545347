import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import { useAxiosClientAuth } from './axios'

import ProtectedRoute from './components/ProtectedRoute'
import './scss/style.scss'

const loading = (
  <div className='pt-3 text-center'>
    <div className='sk-spinner sk-spinner-pulse' />
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  useAxiosClientAuth()

  const currentUser = useSelector((state) => state.session.user)
  const token = useSelector((state) => state.session.token)

  return (
    <>
      <ReactNotification />
      <Router>
        <React.Suspense fallback={loading}>
          <Switch>
            <Route
              exact
              path='/login'
              name='Login Page'
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path='/register'
              name='Register Page'
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path='/404'
              name='Page 404'
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path='/500'
              name='Page 500'
              render={(props) => <Page500 {...props} />}
            />
            <ProtectedRoute
              path='/'
              auth={Boolean(currentUser && token)}
              fallback={(props) => <Login {...props} />}
              name='Dashboard'
              component={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </Router>
    </>
  )
}

export default App
