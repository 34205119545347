// Action Types.
import {
  FETCH_USERS_ONLINE_REQUEST,
  FETCH_USERS_ONLINE_SUCCESS,
  FETCH_USERS_ONLINE_ERROR
} from '../actions/actionTypes'

// Initial State.
const initialState = {
  users: [],
  loading: false,
  error: false
}

const usersListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_ONLINE_SUCCESS:
      return { ...state, users: action.payload, loading: false, error: false }
    case FETCH_USERS_ONLINE_REQUEST:
      return { ...state, loading: true }
    case FETCH_USERS_ONLINE_ERROR:
      return { ...state, loading: false, error: true }
    default:
      return state
  }
}

export default usersListReducer
