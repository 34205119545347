// Action Types.
import {
  CREATE_TRANSACTION_MOCK_SUCCESS,
  CREATE_TRANSACTION_MOCK_REQUEST,
  CREATE_TRANSACTION_MOCK_ERROR,
  CREATE_PLAYER_MOCK_SUCCESS,
  CREATE_PLAYER_MOCK_REQUEST,
  CREATE_PLAYER_MOCK_ERROR
} from '../actions/actionTypes'

// Initial State.
const initialState = {
  userError: false,
  transactionError: false,
  userLoading: false,
  transactionLoading: false
}

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_TRANSACTION_MOCK_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      return { ...state, transactionError: false, transactionLoading: false }
    case CREATE_TRANSACTION_MOCK_REQUEST:
      // eslint-disable-next-line no-case-declarations
      return { ...state, transactionError: false, transactionLoading: true }
    case CREATE_TRANSACTION_MOCK_ERROR:
      // eslint-disable-next-line no-case-declarations
      return { ...state, transactionError: true, transactionLoading: false }
    case CREATE_PLAYER_MOCK_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      return { ...state, userError: false, userLoading: false }
    case CREATE_PLAYER_MOCK_REQUEST:
      // eslint-disable-next-line no-case-declarations
      return { ...state, userError: false, userLoading: true }
    case CREATE_PLAYER_MOCK_ERROR:
      // eslint-disable-next-line no-case-declarations
      return { ...state, userError: true, userLoading: false }
    default:
      return state
  }
}

export default sessionReducer
