// Action Types.
import {
  FETCH_PROVIDER_ERROR,
  FETCH_PROVIDER_SUCCESS
} from '../actions/actionTypes'

// Initial State.
const initialState = {
  providers: []
}

const providerReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROVIDER_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { payload } = action
      return { ...state, providers: payload }
    case FETCH_PROVIDER_ERROR:
      // eslint-disable-next-line no-case-declarations
      return { ...state, providers: [] }
    default:
      return state
  }
}

export default providerReducer
