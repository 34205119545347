import React, { createContext } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { updateBalance } from '../actions/playerDetailsActions'

const WebSocketContext = createContext(null)

export { WebSocketContext }

const Socket = ({ children }) => {
  let socket
  let ws

  const dispatch = useDispatch()
  const usr = useSelector((state) => state.session.user)

  if (usr === null) {
    ws = {}
    console.log('EMPTY CONTEXT <--------------')

    return <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>
  }

  const id = usr.id

  if (!socket) {

    const baseUrl = process.env.REACT_APP_SERVER_URL.replace('http', 'ws')
    socket = new WebSocket(baseUrl + `ws?id=${id}`)

    socket.addEventListener('message', (e) => {
      const payload = JSON.parse(e.data)
      console.log('PAYLOAD FROM SOCKET', payload)
      dispatch(updateBalance(payload))
    })

    ws = {
      socket: socket
    }
  }

  return <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>
}

Socket.propTypes = {
  children: PropTypes.element
}

export default Socket
