import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2'
import storage from 'redux-persist/lib/storage'
import { persistReducer} from 'redux-persist'
import thunk from 'redux-thunk'

// Root reducer
import rootReducer from '../reducers'

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default createStore(persistedReducer, composeWithDevTools(applyMiddleware(thunk)))
